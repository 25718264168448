<template>
<div>
  <div ref="qrcode" class="qr">
  </div>
<div style="
        width: 100%;
        border: solid 1px;
        font-size: 80pt;
        
      ">Clicks: {{clicks || 0}}</div>
</div>
</template>

<script>
export default {
  props:["code", "clicks"],
  methods: {
    generateQrcode(){
      new window.QRCode(this.$refs.qrcode, {
        text: this.code || 'http://google.com',
        width: 250,
        height: 250,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel : window.QRCode.CorrectLevel.H
      })
    }
  },
  mounted(){
    this.generateQrcode()
  }
};
</script>

<style scoped>
.qr{
  margin: 20px;
}
</style>>