<template>
  <div>
    <div>
      <video ref="video" autoplay="true" muted>
        <!-- <source src="video.mp4" type="video/mp4"> -->
      </video>
      
        <focus
          v-for="(face, index) in faces" :key="index"
          :height="(face.height)"
          :width="(face.width)"
          :top="(face.y )"
          :left="(face.x)"
        />
  
    </div>

    <div
      style="
        width: 100%;
        border: solid 1px;
        font-size: 80pt;
        
      "
    >
      Total: {{ totalFaces }}
    </div>
    <div >
      <Qrcode :clicks="clicks" :code="'https://rasp.plataformatn.com.br/r/'+newsId+'/999'" />
    </div>
    
  </div>
</template>

<script>
//import { facesDetected } from '../../services/rasp';
import Qrcode from './Qr.vue'
import Focus from "./Focus.vue";
import { getClicks } from '../../services/rasp';
//import * as faceapi from "face-api.js";

let timeOutInstance = 0
const timeout = (time = 2000)=>new Promise(resolve => {
  clearTimeout(timeOutInstance)
  timeOutInstance = setTimeout(resolve, time)
  })

export default {
  props: ["current"],
  components: { Focus, Qrcode },
  data: () => ({
    video: null,
    img: document.createElement("img"),
    canvas: document.createElement("canvas"),
    ctx: null,
    totalFaces: 0,
    faces: [],
    clicks: 0,
    newsId: '609173afd6f76870bf293d5e'//id da notícia mostrada no qrcode
  }),
  methods: {
    onloadImage() {
      return window.$(this.img).faceDetection({
        complete: async (faces) => {
          const qtd = faces.filter((fc) => fc.confidence > 0);
          
            this.faces = qtd
            this.totalFaces = qtd.length
          
          setTimeout(this.reconhece, 3000);
        },
      });
    },
    async reconhece() {
      this.canvas.width = this.video.offsetWidth;
      this.canvas.height = this.video.offsetHeight;
      this.ctx.filter = "brightness(1.7)";
      this.ctx.drawImage(
        this.video,
        0,
        0,
        this.canvas.width,
        this.canvas.height
      );
      this.img.src = this.canvas.toDataURL("image/jpeg", 0.5);

    },
   
    async init() {

      this.img.onload = this.onloadImage;
      this.video = this.$refs.video;
      this.ctx = this.canvas.getContext("2d", {willReadFrequently: true});
      navigator.mediaDevices
        .getUserMedia({ video: { facingMode: "user" }, audio: false })
        .then(async (stream) => {
          this.video.srcObject = stream;
          this.reconhece();
        });
    },
    async getCLicks(){
      const response = await getClicks(this.newsId).catch(console.error)
      this.clicks = response.total
      await timeout()
      this.getCLicks()
    }
  },
  mounted() {
    this.init();
    this.getCLicks();
  },
};
</script>